//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { documentTabAdd, documentTabEdit } from '@/api/modular/fileStream/processingSignature'
export default {
    data() {
        return {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 },
            },
            visible: false,
            confirmLoading: false,
            form: this.$form.createForm(this),
            title: '新增處理箋名稱',
            rangs: [],
        }
    },
    methods: {
        /**
         * 提交表單
         */
        handleSubmit() {
            const {
                form: { validateFields },
            } = this
            validateFields((errors, values) => {
                if (!errors) {
                    this.confirmLoading = true
                    const params = {
                        ...values,
                    }
                    const func = params?.id ? documentTabEdit : documentTabAdd
                    func(values)
                        .then((res) => {
                            if (res.success) {
                                this.$message.success('新增成功')
                                this.confirmLoading = false
                                this.$emit('ok', values)
                                this.handleCancel()
                            } else {
                                this.$message.error(res.message || '新增失敗') // + res.message
                            }
                        })
                        .finally((res) => {
                            this.confirmLoading = false
                        })
                }
            })
        },
        handleCancel() {
            this.form.resetFields()
            this.handleVisible()
        },
        handleVisible() {
            this.visible = !this.visible
        },
        // 初始化方法
        open(info) {
            this.handleVisible()
            if (info) {
                this.title = '編輯處理箋名稱'
                setTimeout(() => {
                    this.form.setFieldsValue({
                        id: info.id,
                        name: info.name,
                        typeList: info.typeList,
                    })
                }, 0)
            }
        },
    },
}
